<template>
<div>
    <v-main>
        <appbar v-if="!$route.meta.hideNavigation"/>
        <transition name="router-anim" enter-active-class="animated zoomIn">
                <div v-if="!$route.meta.hideNavigation">
                    <router-view :key="$route.path" />
                </div>
                <router-view :key="$route.path" v-else />
        </transition>
    </v-main>
</div>
</template>

<script>
export default {
    components: {
        appbar: () => import('./appbar'),
    },
    // created(){
    //     this.$store.dispatch('loadRoles')
    // }
}
</script>
